/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { mediaQueryList } from "../helpers"

import Text from "./text"

const RollOverCard = ({ background, title, description, customStyle,className="" }) => {
  const ref = React.useRef(null)
  const [height, setHeight] = React.useState(0)
  const containerHeight = 645
  React.useEffect(() => setHeight(ref.current.clientHeight), [])
  return (
    <div
      sx={{
        [`${mediaQueryList()[2]}`]: {
          position: "relative",
          height: [containerHeight],
          overflow: "hidden",
          ":hover": {
            "div:nth-of-type(1):after": {
              opacity: "0.88",
            },
            "div:nth-of-type(2)": {
              // TODO: get the real height of the Text container
              top: containerHeight - height,
            },
          },
        },
      }}
      className={className}
    >
      <div
        sx={{
          width: "100%",
          background: background,
          backgroundSize: "cover !important",
          backgroundPosition: "center !important",
          height: [0, 0, containerHeight],
          pb: ["55%", "55%", 0],
          mb: [20, 20, null],
          borderRadius: "5px",
          [`${mediaQueryList()[2]}`]: {
            maxWidth: "400px",
            position: "relative",
            ":after": {
              backgroundColor: "rgba(19, 121, 157, 1)",
              opacity: 0,
              transition: "opacity 1s ease 0s",
              content: '""',
              display: "block",
              height: "100%",
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
            },
            ...customStyle
          },
        }}
      ></div>

      <div
        ref={ref}
        sx={{
          position: "relative",
          [`${mediaQueryList()[2]}`]: {
            padding: "32px",
            position: "absolute",
            top: containerHeight - 88,
            width: "100%",
            transition: " top 1s ease 0s",
            zIndex: 2,
          },
        }}
      >
        <Text
          customStyle={{
            mb: 1,
            ":last-child": { mb: 0 },
            [`${mediaQueryList()[2]}`]: {
              color: "white",
            },
          }}
          type="h3"
        >
          {title}
        </Text>
        {description &&
          documentToReactComponents(description.json, {
            renderNode: {
              [BLOCKS.UL_LIST]: (node, children) => (
                <ul
                  
                >
                  {children}
                </ul>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Text
                  customStyle={{
                    my: theme => [20, theme.spacing.vertical.sm],
                    [`${mediaQueryList()[2]}`]: {
                      color: "white",
                    },
                  }}
                >
                  {children}
                </Text>
              ),
            },
          })}
      </div>
    </div>
  )
}
RollOverCard.propTypes = {
  background: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.object,
}

export default RollOverCard
