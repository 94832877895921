/** @jsx jsx */
import { jsx } from "theme-ui"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"

import { renderLineBreak } from "../helpers"

import Container from "../components/container"
import Column from "../components/column"
import Row from "../components/row"
import Text from "../components/text"

const AwardsModule = ({ bladePatternData, titleAlignament, titleTextType }) => {
  return (
    <Container customStyle={{ py: theme => theme.spacing.vertical.md }}>
      <Row>
        <Column>
          <Text
            type={titleTextType || "h3"}
            customStyle={{
              textAlign: titleAlignament || "left",
            }}
          >
            {renderLineBreak(bladePatternData.title)}
          </Text>
        </Column>
      </Row>
      <Row
        customStyle={{
          mt: [
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.md,
          ],
          p: theme => theme.spacing.horizontal,
        }}
      >
        {/* TODO: Rename contentCards to basicCardsList after updating Contentful model*/}
        {/* TODO: make this a component */}
        {bladePatternData.contentCards.map((basicCard, idx) => {
          return (
            <Column
              customStyle={{
                alignItems: [
                  "flex-start",
                  "flex-start",
                  "flex-start",
                  "center",
                ],
                mb: [
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  0,
                ],
                ":last-child": { mb: 0 },
              }}
              size={[12, 12, 12, null]}
              key={kebabCase(basicCard.title)}
            >
              <Flex sx={{ alignItems: "center" }}>
                <img
                  sx={{ maxWidth: 140 }}
                  src={basicCard.desktopImage.file.url}
                  alt=""
                />
                {basicCard.title && (
                  <Text customStyle={{ fontWeight: "heading", ml: 3 }}>
                    {renderLineBreak(basicCard.title)}
                  </Text>
                )}
              </Flex>
            </Column>
          )
        })}
      </Row>
    </Container>
  )
}

export default AwardsModule
