/** @jsx jsx */
import { jsx } from "theme-ui"
import Modal from "./modal"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Container from "../components/container"
import Column from "../components/column"
import Text from "../components/text"

const BiosModal = ({ onClose, data }) => {
  return (
    <Modal
      customStyle={{ maxWidth: [null, null, null, 1000] }}
      onClose={onClose}
    >
      <div
        sx={{
          py: theme => theme.spacing.vertical.sm,
          paddingLeft: "40px",
          paddingRight: "35px",
        }}
      >
        <Container>
          <Row>
            <Column
              size={[12, 12, 12]}
              customStyle={{
                mb: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.md,
                ],
              }}
            >
              <Text
                type="h4"
                customStyle={{
                  paddingBottom: "8px",
                }}
              >
                {!data.isLetter ? data.title : "Steve Glenn"}
              </Text>
              {!data.isLetter ? (
                <RichText data={data.description} />
              ) : (
                <Text customStyle={{ paddingBottom: "20px" }}>
                  {"Founder & CEO"}
                </Text>
              )}
              <RichText data={data.bios} />
            </Column>
          </Row>
        </Container>
      </div>
    </Modal>
  )
}

export default BiosModal
